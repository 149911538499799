import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { NavItem, SocialNavItem } from "./typings"

interface NavigationProps {
  nav: Array<NavItem>
  social: Array<SocialNavItem>
  showScrollProgress?: boolean
  scrollTitle?: string
  scrollImage?: string
}
const Navigation = ({
  nav,
  social,
  showScrollProgress,
  scrollImage,
  scrollTitle,
}: NavigationProps) => {
  const data = useStaticQuery(graphql`
    {
      logo2Image: file(relativePath: { eq: "logo2.png" }) {
        childImageSharp {
          gatsbyImageData(width: 800, placeholder: BLURRED, layout: CONSTRAINED)
        }
      }
      scrollImage: file(relativePath: { eq: "bg/long/Apo3D-pylon.jpg" }) {
        childImageSharp {
          gatsbyImageData(height: 1080, layout: FULL_WIDTH)
        }
      }
    }
  `)
  const img =
    scrollImage || data.scrollImage.childImageSharp.gatsbyImageData.src
  // console.info({ img })
  return (
    <>
      <div className="nav-holder but-hol">
        <div className="nav-scroll-bar-wrap fl-wrap">
          <Link to="/" className="ajax logo_menu">
            <img
              src={data.logo2Image.childImageSharp.gatsbyImageData.src}
              alt=""
            />
          </Link>
          <div className="nav-container fl-wrap">
            <nav className="nav-inner" id="menu">
              <ul>
                {nav.map(item =>
                  Array.isArray(item.sub) ? (
                    <>
                      <li key={item.name}>
                        <a href="#">{item.name}</a>
                        {Array.isArray(item.sub) && (
                          <ul>
                            {item.sub.map(subItem => (
                              <li key={subItem.name}>
                                <Link
                                  to={subItem.slug}
                                  activeClassName="act-link"
                                  className="ajax"
                                >
                                  {subItem.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    </>
                  ) : (
                    <Link
                      key={item.name}
                      to={item.slug}
                      activeClassName="act-link"
                      className={`${Array.isArray(item.sub) && "ajax"}`}
                    >
                      {item.name}
                    </Link>
                  )
                )}
              </ul>
            </nav>
          </div>
        </div>
        <div className="share-wrapper">
          <span className="share-title">Share : </span>
          <div className="share-container" />
        </div>
        <div className="nav-holder-line" />
      </div>
      <div className="nav-holder-dec color-bg" />
      <div className="nav-overlay" />

      {showScrollProgress && (
        <div className="fixed-column-wrap">
          <div className="progress-bar-wrap">
            <div className="progress-bar color-bg" />
          </div>
          <div className="column-image fl-wrap full-height">
            <div
              className="bg bg-scroll"
              // data-bg={scrollImage}
              style={{
                backgroundImage: `url("${img}")`,
              }}
            />
            <div className="overlay" />
            <div className="column-image-anim" />
          </div>
          <div className="fcw-dec" />
          <div className="fixed-column-tilte fdct fcw-title">
            <span id="quote">{scrollTitle}</span>
          </div>
        </div>
      )}
    </>
  )
}

export default Navigation
