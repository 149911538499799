import { useStaticQuery, graphql } from "gatsby"
export const useSiteMetadata = () => {
  const data = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            title
            social {
              name
              icon
              url
            }
            mainNavLinks {
              name
              slug
              sub {
                name
                slug
              }
            }
          }
        }
        aboutImage: file(relativePath: { eq: "about.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              width: 80
              height: 80
              placeholder: BLURRED
              transformOptions: { cropFocus: ATTENTION }
              layout: FIXED
            )
          }
        }
      }
    `
  )
  return data
}
