import { graphql, Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle, social = [] }) => {
  const data = useStaticQuery(graphql`{
  logoImage: file(relativePath: {eq: "logo.png"}) {
    childImageSharp {
      gatsbyImageData(width: 800, placeholder: BLURRED, layout: CONSTRAINED)
    }
  }
}
`)

  return (
    <header className="main-header">
      <Link to="/" className="logo-holder ajax">
        <img src={data.logoImage.childImageSharp.gatsbyImageData.src} alt="" />
      </Link>

      {/* <!-- nav-button-wrap--> */}
      <div className="nav-button but-hol">
        <span className="nos" />
        <span className="ncs" />
        <span className="nbs" />
        <div className="menu-button-text">Menu</div>
      </div>
      {/* <!-- nav-button-wrap end--> */}
      <div className="header-social">
        <ul>
          {social.map((item, index) => (
            <li key={index}>
              <a
                href={item.url}
                target="_blank"
                title={item.name}
                rel="noreferrer"
              >
                <i className={`fab fa-${item.icon}`} />
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className="folio-btn">
        <Link className="folio-btn-item ajax" to="/portfolio" />
        <span className="folio-btn-tooltip">My Portfolio</span>
      </div>
    </header>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
