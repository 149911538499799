import * as React from "react"

const Footer = () => (
  <>
    <div className="height-emulator fl-wrap" />
    <footer className="main-footer fixed-footer">
      <div className="container">
        <div className="footer-inner fl-wrap">
          <div className="row">
            <div className="col-md-6">
              <div className="footer-box fl-wrap">
                <div className="footer-title fl-wrap">
                  <span>001.</span>
                  Latest Tweets
                </div>
                <div className="footer-box-item fl-wrap">
                  <div
                    className="twitter-swiper-container fl-wrap"
                    id="twitts-container"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="footer-title fl-wrap">
                <span>002.</span>
                Subscribe / Contacts
              </div>
              <div className="footer-box-item fl-wrap">
                <p>
                  Want to be notified when we launch a new template or an
                  update. Just sign up and we'll send you a notification by
                  email.
                </p>
                <div className="subcribe-form fl-wrap">
                  <form id="subscribe" className="fl-wrap">
                    <input
                      className="enteremail"
                      name="email"
                      id="subscribe-email"
                      placeholder="Your Email"
                      spellCheck="false"
                      type="text"
                    />
                    <button
                      type="submit"
                      id="subscribe-button"
                      className="subscribe-button color-bg"
                    >
                      <i className="fal fa-paper-plane" /> Send
                    </button>
                    <label
                      htmlFor="subscribe-email"
                      className="subscribe-message"
                    />
                  </form>
                </div>

                <div className="footer-contacts fl-wrap">
                  <ul>
                    {/*<li>
                      <i className="fal fa-phone" />
                      <span>Phone :</span>
                      <a href="#">+489756412322</a>
                    </li>*/}
                    <li>
                      <i className="fal fa-envelope" />
                      <span>Email :</span>
                      <a href="#">info@jerezbain.dev</a>
                    </li>
                    {/*<li>
                      <i className="fal fa-map-marker" />
                      <span>Address</span>
                      <a href="#">USA 27TH Brooklyn NY</a>
                    </li>*/}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="subfooter fl-wrap">
          <div className="policy-box">
            <span>&#169; Jerez Bain 2020 / All rights reserved. </span>
          </div>

          <div className="to-top to-top-btn color-bg">
            <span>To top</span>
          </div>
        </div>
      </div>
      <div className="sec-lines" />
      <div className="footer-canvas">
        <div className="dots gallery__dots" data-dots="" />
      </div>
    </footer>
  </>
)

export default Footer
