/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from "react"
import { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
// import $ from "jquery"

import Header from "./header"
import "./layout.scss"
import Footer from "./footer"
import Navigation from "./navigation"
import { useSiteMetadata } from "../hooks/useSiteMetadata"

const Layout = ({ title, scrollImage, showScrollProgress, children }) => {
  const data = useSiteMetadata()

  function firstLoad() {
    window.TweenMax.to(window.$(".loader-inner"), 1.0, {
      force3D: true,
      y: "-150px",
      opacity: 0,
      ease: window.Expo.easeInOut,
      onComplete: function () {
        window.TweenMax.to(window.$(".loader-bg"), 0.8, {
          force3D: true,
          bottom: "100%",
          ease: window.Expo.easeInOut,
        })
        window.TweenMax.to(window.$(".loader-wrap"), 0.8, {
          force3D: true,
          bottom: "100%",
          ease: window.Expo.easeInOut,
          delay: 0.2,
        })
        setTimeout(function () {
          window.initpageloadAnimation()
        }, 600)
      },
    })
    const chdpt = window.$(".content-holder").data("pagetitle")
    window.$(".page-load_bg span").text(chdpt)
  }

  useEffect(() => {
    const isBrowser = typeof window !== "undefined"

    if (isBrowser) {
      window.ball = document.querySelector(".element-item")

      window
        .$("<span class='folio-btn-dot'/>")
        .duplicate(9)
        .appendTo(".folio-btn-item")

      window.TweenLite.set(window.ball, {
        xPercent: -50,
        yPercent: -50,
      })
      document.addEventListener("mousemove", window.mouseMove)
      window.TweenMax.ticker.addEventListener("tick", window.updatePosition)

      // window.$.coretemp({
      //   reloadbox: "#wrapper",
      //   outDuration: 700,
      //   inDuration: 200,
      // })
      window.readyFunctions()
      $(document).on({
        ksctbCallback: function () {
          window.readyFunctions()
        },
      })

      firstLoad()
    }
  }, [])

  return (
    <>
      <div>
        {/*<div className="loader-wrap color-bg">
          <div className="loader-bg" />
          <div className="loader-inner">
            <div className="loader" />
          </div>
        </div>*/}

        <main id="main">
          <Header
            siteTitle={data.site.siteMetadata.title}
            social={data.site.siteMetadata.social}
          />
          <div id="wrapper">
            <div
              className="content-holder scroll-content"
              data-pagetitle={title}
            >
              <Navigation
                nav={data.site.siteMetadata.mainNavLinks}
                social={data.site.siteMetadata.social}
                showScrollProgress={showScrollProgress}
                scrollTitle={title}
                scrollImage={scrollImage}
              />
              {children}
              <Footer />
            </div>
          </div>
          <div className="element">
            <div className="element-item" />
          </div>
        </main>
        {/*<footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer>*/}
      </div>
    </>
  )
}

Layout.propTypes = {
  title: PropTypes.string.isRequired,
  scrollImage: PropTypes.string,
  showScrollProgress: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  showScrollProgress: true,
}
export default Layout
